import React from 'react';
import { ConfigProvider, theme } from 'antd';
import RouteF from './RouteF';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <div>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#C93438',
              borderRadius:2,
              borderrad2: 8,
              borderrad3: 5,
              borderCardrad:20,
              borderCardcolor: '#fff',
              cardshadow:'0px 4px 25px 0px #D9D9D9',
              mainColor: '#C93438',
              secondarColor:'#04103B',
              colorgray: '#949494',
              bgtransparent:'transparent',
              border2:'#D9D9D9',
            },
          }}
        >
          <RouteF theme={theme.components} />
        </ConfigProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
