import React from "react"
import { Drawer, Form, Row, Col ,Button, Space, theme} from "antd"
import { StoreInput } from "../input"
const { useToken } = theme;

 
const SettingModal = ({visible, onClose}) => {
    const [form] = Form.useForm()
    const { token } = useToken();
    

  return (
    <div>
        <Drawer
            title='Settings'
            onClose={onClose}
            visible={visible}
            destroyOnClose
            width={500}
        >
            <div className="flex-col-Space-between height-100">
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={true}
                    onFinish={()=>{}}
                    style={{height:'100%',overflowY:"auto"}}
                >
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12}>
                            <StoreInput
                                name="olpassword"
                                type='password'
                                label="Old password"
                                size='large'
                                required
                                message='please enter old password'
                                value={form.getFieldValue('olpassword') || ''}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <StoreInput
                                name="npassword"
                                type='password'
                                label="New password"
                                size='large'
                                required
                                message='please enter new password'
                                value={form.getFieldValue('npassword') || ''}
                            />
                        </Col>
                    </Row>
                </Form>
                <div className="w-100">
                    <Space style={{justifyContent:"end"}} className="w-100">
                        <Button 
                            block
                            type=""
                            size="large"
                            onClick={onClose}
                            style={{borderColor: token.border2,width: '120px',borderRadius: token.borderrad3}}
                        >
                            Cancel
                        </Button>
                        <Button  
                            block
                            type="primary"
                            size="large"
                            style={{borderColor: token.border2,width: '120px',borderRadius: token.borderrad3}}
                        >
                            Save
                        </Button>
                    </Space>
                </div>
            </div>
        </Drawer>
    </div>
  )
}

export {SettingModal}