import { useState } from "react"
import { Button, Space, Typography, Avatar, Image} from "antd"
import {
    CaretDownOutlined
} from '@ant-design/icons'
import UserProfileDrawer from "./UserProfileDrawer"
const {Title}= Typography

export const UserProfile = () => {
    const [visible, setVisible]= useState(false)
    return (
        <>
            <Space 
                    style={{ cursor: 'pointer' }}
                    onClick={()=> setVisible(true)}
                >
                    <Button shape='circle' className='border0 p-0' icon={
                      <Avatar
                        style={{background:"transparent"}} size={32}
                        icon={
                          <Image 
                            src='/assets/av-1.png' 
                            preview={false}
                          />
                        }

                      />
                    }>
                    </Button>
                    <Space size={2}>
                        <Title level={5} className='my-0'>Admin</Title>
                        <CaretDownOutlined style={{ transform: 'translateY(2px)' }} />
                    </Space>
                </Space>
            <UserProfileDrawer
                visible={visible}
                onClose={()=> setVisible(false)}
            />
        </>
    )
}