import React from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Form, Dropdown, Table, Avatar } from 'antd'
import { StoreSearch } from '../../components'
const { useToken } = theme;
const { Title, Text } = Typography

const suffix = (
    <Image src='/assets/icons/search-ic.png' width={'14.86px'} />
  );

const AppTable = () => {
    const [form] = Form.useForm()
    const { token } = useToken();
    const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        },
        {
            label: <a href="#">Most downloads</a>,
            key: '2',
        },
        {
            label: <a href="#">Less downloads</a>,
            key: '3',
        },
    ];


      const columns = [
        {
            title: <Text style={{color: token.colorgray}}>Application name</Text>,
            dataIndex: 'appname',
            key: 'appname',
        },
        {
            title: <Text style={{color: token.colorgray}}>Category</Text>,
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: <Text style={{color: token.colorgray}}>Version</Text>,
            dataIndex: 'version',
            key: 'version',
        },
        {
            title: <Text style={{color: token.colorgray}}>Downloads</Text>,
            dataIndex: 'downloads',
            key: 'downloads',
        },
      ];
      const data = [
        {
          key: '1',
          appname: <Space size={20}>
            <Avatar style={{background:"transparent"}} size={64} icon={<Image src='/assets/ytube.png' />} />
            <Text strong>Youtube</Text>
          </Space>,
          category: <Text strong>Movie and series</Text>,
          version: <Text strong>1.0.1</Text>,
          downloads: <Text strong>25k</Text>
        },
        {
            key: '2',
            appname: <Space size={20}>
              <Avatar style={{background:"transparent"}} size={64} icon={<Image src='/assets/netflix.png' />} />
              <Text strong>Netflix</Text>
            </Space>,
            category: <Text strong>Entertainment</Text>,
            version: <Text strong>1.0.2</Text>,
            downloads: <Text strong>17k</Text>
        },
        {
            key: '3',
            appname: <Space size={20}>
              <Avatar style={{background:"transparent"}} size={64} icon={<Image src='/assets/gmail.png' />} />
              <Text strong>Google Chrome</Text>
            </Space>,
            category: <Text strong>Browser</Text>,
            version: <Text strong>1.0.1</Text>,
            downloads: <Text strong>34.5k</Text>
        },
        {
            key: '4',
            appname: <Space size={20}>
              <Avatar style={{background:"transparent"}} size={64} icon={<Image src='/assets/bbc.png' />} />
              <Text strong>BBC News</Text>
            </Space>,
            category: <Text strong>News</Text>,
            version: <Text strong>1.0.3</Text>,
            downloads: <Text strong>18k</Text>
        },
        {
            key: '5',
            appname: <Space size={20}>
              <Avatar style={{background:"transparent"}} size={64} icon={<Image src='/assets/cnn.png' />} />
              <Text strong>CNN News</Text>
            </Space>,
            category: <Text strong>News</Text>,
            version: <Text strong>1.0.3</Text>,
            downloads: <Text strong>18k</Text>
        },
        {
            key: '6',
            appname: <Space size={20}>
              <Avatar style={{background:"transparent"}} size={64} icon={<Image src='/assets/disney.png' />} />
              <Text strong>Disney +</Text>
            </Space>,
            category: <Text strong>Movie and series</Text>,
            version: <Text strong>1.0.3</Text>,
            downloads: <Text strong>18k</Text>
        },
        {
            key: '7',
            appname: <Space size={20}>
              <Avatar style={{background:"transparent"}} size={64} icon={<Image src='/assets/d.png' />} />
              <Text strong>Dailymotion</Text>
            </Space>,
            category: <Text strong>Movie and series</Text>,
            version: <Text strong>1.0.3</Text>,
            downloads: <Text strong>18k</Text>
        },
        {
            key: '8',
            appname: <Space size={20}>
              <Avatar style={{background:"transparent"}} size={64} icon={<Image src='/assets/gain.png' />} />
              <Text strong>Gain TV</Text>
            </Space>,
            category: <Text strong>Movie and series</Text>,
            version: <Text strong>1.0.3</Text>,
            downloads: <Text strong>18k</Text>
        },
      ];


  return (
    <div>
        <Card style={{borderRadius: token.borderCardrad}}>
            <Row gutter={[24,24]}>
                <Col md={12}>
                    <div>
                        <Title level={3} className='text-capitalize'>applications</Title>
                    </div>
                </Col>
                <Col md={12}>
                    <Space align='baseline' className='w-100' size={10} style={{justifyContent:"end"}}>
                        <StoreSearch
                            name="search"
                            size='large'
                            value={form.getFieldValue('search') || ''}
                            suffix={suffix}
                            placeholder='Search'
                            className='borderrad'
                        />
                        <Dropdown
                            menu={{
                            items,
                            }}
                            trigger={['click']}
                        >
                            <a onClick={(e) => e.preventDefault()} className='borderbtn my-0'>
                                <Space>
                                    <Image src='/assets/icons/filteric.png' width={'16px'} preview={false} />
                                    <Text className='label'>
                                        Filter
                                    </Text>
                                </Space>
                            </a>
                        </Dropdown>
                    </Space>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        pagination={{
                            position: ['bottomCenter']
                        }}
                        scroll={{
                            x: 1000,
                            y: 1000,
                          }}
                    />
                </Col>
            </Row>
        </Card>
    </div>
  )
}

export {AppTable}