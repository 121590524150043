import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Button, Pagination } from 'antd'
import {
    EditOutlined
} from '@ant-design/icons'
import { EditCategoryDrawer } from '../EditCategoryDrawer';
const { useToken } = theme;
const { Title } = Typography

const CategoryCard = () => {
    const { token } = useToken();
    const [visible, setVisible]= useState(false)
    const data = [
        {
            title:'Movies and Series',
            img:'img1.png',
        },
        {
            title:'TV Channels',
            img:'img2.png',
        },
        {
            title:'Music',
            img:'img3.png',
        },
        {
            title:'Tools',
            img:'img4.png',
        },
        {
            title:'News',
            img:'img5.png',
        },
        {
            title:'Browser',
            img:'img6.png',
        },
        {
            title:'Lorem ipsum',
            img:'img7.png',
        },
        {
            title:'Lorem ipsum',
            img:'img7.png',
        },
    ]
  return (
    <div>
        <Row gutter={[24,24]}>
            {
                data?.map((cdata,c)=>
                    <Col md={12} lg={6} key={c}>
                        <Card style={{background: token.bgtransparent,borderColor: token.border2,borderRadius: token.borderrad2, height:"100%"}}>
                            <Space direction='vertical' size={10} style={{justifyContent:"space-between"}} className='w-100'>
                                <Image src={'/assets/'+cdata?.img} className='w-100' height={'176px'} width={'100%'} preview={false} />
                                <Space className='w-100' style={{justifyContent:"space-between"}}>
                                    <Title level={5} className='label my-0'>{cdata?.title}</Title>
                                    <Button shape='circle' onClick={()=>setVisible(true)}>
                                        <EditOutlined />
                                    </Button>
                                </Space>
                            </Space>
                        </Card>
                    </Col>
                )
            }
            <Pagination style={{textAlign:"center"}} className='w-100' defaultCurrent={1} total={10} />
        </Row>
        <EditCategoryDrawer
                visible={visible}
                onClose={()=> setVisible(false)}
        />
    </div>
  )
}

export {CategoryCard}