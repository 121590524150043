import { Form, Image, Button, Space, Row, Col, Typography, Checkbox} from "antd"
import { StoreInput } from '../../components'
import "./index.css"
import { useState } from "react"
const {Title}= Typography

const Loginpage = () => {

    const [form] = Form.useForm()
    const [check, setCheck] = useState()

    const onChange = (e) =>{
       setCheck(e.target.checked)
    }
    return (
        <div 
            className='login-form-cover'
            >
            <div className="center">
                <Row className="login-form border-grey">
                    <Col md={11} sm={24} xs={24}>
                        <Space 
                            direction='vertical' 
                            className='py-5 my-2 px-4 width-100'
                            size={15}
                            >
                            <div>
                                <div className="justify-center">
                                    <Image
                                        src={"./assets/logo-b.png"}
                                        alt='Store App Admin Panel'
                                        preview= {false}
                                        width={'80px'}
                                    />
                                </div>
                                <Title 
                                    level={5} 
                                    className="my-0 text-center brand-color fs-14"
                                    >
                                    Login to access your dashboard
                                </Title>
                            </div>
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={{
                                    remember: true,
                                }}
                                style={{ width: '100%' }}
                            >
                                <Row>
                                    <Col span={24}>
                                        <StoreInput
                                            name="email"
                                            label="Username"
                                            required
                                            message='please enter username'
                                            size='large'
                                            value={form.getFieldValue('username') || ''}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <StoreInput
                                            name="password"
                                            type='password'
                                            label="Password"
                                            size='large'
                                            required
                                            message='please enter password'
                                            value={form.getFieldValue('password') || ''}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox onChange={onChange} className="mb-3">Checkbox</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Button 
                                            type="primary"
                                            htmlType="submit" 
                                            size="large"
                                            block
                                            style={{borderRadius:"0px"}}
                                            >
                                            Login
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Space>
                    </Col>
                    <Col 
                        md={13} sm={0} xs={0}
                        className="bg-gray2 flex-col-align-end"
                        >
                            <Space direction="vertical" className="px-4" size={0} align="center" style={{justifyContent:"center",height:"100%"}}>
                                <Image src="/assets/login-right.png" className="w-100" preview={false} />
                                <Title 
                                    level={5} 
                                    className="text-center light-grey-text"
                                    >
                                    Version 1.1.6 Beta Release
                                </Title>
                            </Space>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export {Loginpage}