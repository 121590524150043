import React,{ useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, theme, Button, Pagination } from 'antd'
import {
    EditOutlined
} from '@ant-design/icons'
import { EditAppDrawer } from '../EditAppDrawer';
const { useToken } = theme;
const { Title, Text } = Typography

const AppCards = () => {
    const { token } = useToken();
    const [visible, setVisible]= useState(false)
    const data = [
        {
            title:'Gain TV',
            img:'app1.png',
        },
        {
            title:'Amazon Prime Video',
            img:'app2.png',
        },
        {
            title:'Twitch',
            img:'app3.png',
        },
        {
            title:'Disney +',
            img:'app4.png',
        },
        {
            title:'Puhu TV',
            img:'app5.png',
        },
        {
            title:'Netflix',
            img:'app6.png',
        },
        {
            title:'Blue TV',
            img:'app7.png',
        },
        {
            title:'Exxen',
            img:'app8.png',
        },
    ]
  return (
    <div>
        <Row gutter={[24,24]}>
            {
                data?.map((cdata,c)=>
                    <Col xs={24} sm={24} md={12} lg={6} key={c}>
                        <Card style={{background: token.bgtransparent,borderColor: token.border2,borderRadius: token.borderrad2, height:"100%"}}>
                            <Space direction='vertical' size={10} style={{justifyContent:"space-between"}} className='w-100'>
                                <Image src={'/assets/'+cdata?.img}  height={'176px'} width={'100%'} preview={false} />
                                <Space className='w-100' style={{justifyContent:"space-between"}}>
                                    <Title level={5} className='label my-0'>{cdata?.title}</Title>
                                    <Button shape='circle' onClick={()=>setVisible(true)}>
                                        <EditOutlined />
                                    </Button>
                                </Space>
                            </Space>
                        </Card>
                    </Col>
                )
            }
            <Pagination style={{textAlign:"center"}} className='w-100' defaultCurrent={1} total={10} />
        </Row>
        <EditAppDrawer
                visible={visible}
                onClose={()=> setVisible(false)}
        />
    </div>
  )
}

export {AppCards}