import React,{ useState } from 'react'
import { Button, Image, Space, Row, Col, Form, Typography, Card } from 'antd'
import { AddAppDrawer, AppCards, StoreSearch } from '../../components';
const { Title } = Typography
const suffix = (
  <Image src='/assets/icons/search-ic.png' width={'14.86px'} />
);

const AppPage = () => {
  const [form] = Form.useForm()
  const [visible, setVisible]= useState(false)
  return (
    <Card>
      <Space direction='vertical' size={10} className='w-100'>
          <Row gutter={[24,24]}>
              <Col md={12}>
                  <Space>
                      <Title level={3} className='text-capitalize my-0'>Movies and Series</Title>
                      <Button className='border0' onClick={()=>setVisible(true)}>
                          <Image src='/assets/icons/add.png' width={'24px'} preview={false} />
                      </Button>
                  </Space>
              </Col>
              <Col md={12}>
                  <Space align='baseline' className='w-100' size={10} style={{justifyContent:"end"}}>
                      <StoreSearch
                          name="search"
                          size='large'
                          value={form.getFieldValue('search') || ''}
                          suffix={suffix}
                          placeholder='Search'
                          className='borderrad'
                      />
                  </Space>
              </Col>
          </Row>
          <AppCards />
      </Space>

      <AddAppDrawer
                visible={visible}
                onClose={()=> setVisible(false)}
            />
    </Card>
  )
}

export {AppPage}