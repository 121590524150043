import React from "react"
import { Drawer, Button, message, Form, Row, Col, Upload, Image} from "antd"
import "./index.css"
import { StoreInput } from "../../input";
const { Dragger } = Upload;

const UserProfileDrawer= ({visible, onClose})=>{

    const [form] = Form.useForm()


    const props = {
        name: 'file',
        multiple: true,
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
      };

    return (
        <Drawer
            title='Profile'
            onClose={onClose}
            visible={visible}
            width={400}
            footer={null}
        >
            <div className="flex-col-Space-between height-100">
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={true}
                    onFinish={()=>{}}
                >
                    <div className="py-3">
                        <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <Image src='/assets/icons/upload.png' width={'41.6px'} />
                            </p>
                            <p className="ant-upload-text">Upload profile picture</p>
                        </Dragger>
                    </div>
                    <Row gutter={16}>
                        <Col span={12}>
                            <StoreInput
                                name='fName'
                                label='First Name'
                                required
                                message='Please enter first name'
                                placeholder='e.g Jhon'
                                value={form.getFieldValue('fName')}
                            />
                        </Col>
                        <Col span={12}>
                            <StoreInput
                                name='lName'
                                label='Last Name'
                                required
                                message='Please enter name'
                                placeholder='e.g Watson'
                                value={form.getFieldValue('lName')}
                            />
                        </Col>
                        <Col span={12}>
                            <StoreInput
                                name='email'
                                label='Email'
                                required
                                message='Please enter email'
                                placeholder='e.g example@gmail.com'
                                value={form.getFieldValue('email')}
                            />
                        </Col>
                        <Col span={12}>
                            <StoreInput
                                name="role"
                                label='Role'
                                required
                                message='Please select role'
                                value={form.getFieldValue('role')}
                            />
                        </Col>
                    </Row>
                </Form>
                <div className="w-100 btm-btn">
                    <Button  
                        block
                        type="primary"
                        size="large"
                        className="w-100"
                    >
                        Logout
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}
export default UserProfileDrawer