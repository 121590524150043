import React, { useState, useEffect } from 'react';
import { useNavigate, Route, Routes, useLocation} from 'react-router-dom'
import './index.css'
import { Layout, Menu, Image, Space, Typography} from 'antd';
import { AppPage, CategoryPage, Dashboard, Loginpage } from '../pages';
import { Notifications, UserProfile } from '../components/Header';
import { SettingModal } from '../components';
const { Header, Sider, Content } = Layout

const { Title } = Typography

const RouteF = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState([])
  const [currentTab, setCurrentTab]= useState('1')
  const [visible, setVisible] = useState(false)
  function getItem(label, key, icon, onClick, children, type) {
    return { key, icon, onClick, children, label, type }
  }


  useEffect(()=>{
    let tab= location?.pathname?.split("/")[1]
    tab= tab==='' ? '1':
        tab==='category' ? '2':
        tab==='app' ? '3':
        tab==='setting' ? '4':
        '1'
    setCurrentTab(tab)
  }, [location])


  useEffect(() => {
    let itemsArr = []
      itemsArr.push(getItem('Dashboard', '1', 
          <img src="/assets/icons/dashboard.png" width={'20px'} preview={false} alt="" />, 
          () => { navigate("/", { replace: true }) }))
      itemsArr.push(getItem('Category', '2', 
          <img src="/assets/icons/category.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/category", { replace: true }) }))
      itemsArr.push(getItem('Apps', '3', 
          <img src="/assets/icons/apps.png" width={'20px'} preview={false} alt="" /> , 
          () => { navigate("/app", { replace: true }) }))
      itemsArr.push(getItem('Settings', '4', 
          <img src="/assets/icons/setting.png" width={'20px'} preview={false} alt="" /> , 
          () => { setVisible(true) }))
      setItems([...itemsArr])
    }, [navigate])
  return (
    <Layout style={{ height: '100vh'}}>
      <Sider trigger={null} collapsible collapsed={collapsed} style={{ height: '100vh', overflowY: 'auto' }}>
        <div className="logo" style={{ display: 'flex', justifyContent: 'center' }}>
          <Image
            style={{width: collapsed?"70%":'70px'}}
            height={'auto'}
            src="./assets/logo-w.png"
            alt='Store App Admin Panel'
            
            preview= {false}
          />
        </div>
        <Menu
          defaultSelectedKeys={['1']}
          selectedKeys={[currentTab]}
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          items={items}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <div style={{ width: '95%', display: 'flex', justifyContent: 'space-between' }}>
            
                <div className= 'trigger border-0-btn' onClick={() => setCollapsed(!collapsed)}>
                  {
                    collapsed ? 
                    <Image src='/assets/icons/collaps.png' width={'35.46px'} preview={false} />
                    : 
                    <Image src='/assets/icons/collaps.png' width={'35.46px'} preview={false} />
                  }
                </div>
            <Space size={20}>
              <Notifications />
              <UserProfile />
            </Space>
          </div>
        </Header>

        <Content
          className=" scroll-bar"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            overflowY: 'auto'
          }}
        >
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/login" element={<Loginpage />} />
              <Route path="/category" element={<CategoryPage />} />
              <Route path="/app" element={<AppPage />} />
            </Routes>
        </Content>
      </Layout>
      <SettingModal 
        visible={visible}
        onClose={()=>setVisible(false)}
      />
    </Layout>
  )
}

export default RouteF